import { Graphics, Point } from "pixi.js";
import { Interactive } from "./interactive";

export class CueButton extends Interactive {
  protected graphics: Graphics = new Graphics();

  constructor() {
    super();
    this.graphics
      .clear()
      .beginFill(0xff0000, 0.5)
      .drawCircle(0, 0, 32)
      .endFill();
    this.addChild(this.graphics);

    this.graphics.interactive = true;
    this.graphics
      .on('pointerdown', this.buttonPress.bind(this));
  }

  buttonPress(e: PIXI.interaction.InteractionEvent) {
    this.emit("pressed", this, e);;
  }

  multiplierResize(multiplier: number) {
    //
  }

  onTick(beat: number) {
    //
  }

  setState(newState: number, value: number) {
    //
  }

}