import { Container, Application, Point } from "pixi.js";
import ClickTrack from 'click-track';
import State from "./state";
import { InteractiveInstrument, InstrumentState } from "./interactive-instrument";
import { Draggable } from "./dragable";
import { Interactive } from "./interactive";
import mainTrack from "./tracks/main/";
import { CueEvent } from "click-track/dist/src/definitions/cue-event";
import { Fermata } from "./fermata";
import { CueButton } from "./button";

type InteractiveCue = [Interactive, number, number];

export default class PerformanceState extends State {

  protected track: HTMLAudioElement;
  protected interactives: Array<Interactive>;
  private app: Application;
  private clickTrack: ClickTrack<InteractiveCue>;
  private interactivesContainer: Container;

  // DIY interaction management
  private interactiveHovering?: Interactive;
  private mousePos: Point;
  private mouseChecked: boolean = true;
  private draggingObject?: Draggable;

  async createContainer(app: Application): Promise<Container> {
    this.app = app;

    // The container to be returned
    const container = new Container();

    // Get music track information
    const {
      interactives,
      trackUrl,
      tempo,
      offset,
    } = mainTrack();

    const track: HTMLAudioElement = new Audio(trackUrl);
    track.playbackRate = 1;

    const start = () => {
      //track.currentTime = 13 * 6 * (60 / 148);
      track.play();
      track.removeEventListener('canplaythrough', start);
    };
    // This triggers when music track is fully loaded
    track.addEventListener('canplaythrough', start);

    // Assemble interactive things
    this.interactivesContainer = new Container();
    container.addChild(this.interactivesContainer);
    this.interactivesContainer.position.set(window.innerWidth / 2, window.innerHeight * 3 / 4);

    // DIY interaction management
    this.interactivesContainer.interactive = true;
    this.interactivesContainer.on("mousemove", this.onMove.bind(this));

    this.interactives = interactives;

    this.interactives.forEach((s1) => {
      s1.interactive = true;
      this.interactivesContainer.addChild(s1);
    });

    // Create some draggables
    const dragCircle = new Draggable();
    dragCircle.setOrigin(window.innerWidth / 2, window.innerHeight * 3 / 4 + 100);
    container.addChild(dragCircle);
    dragCircle.on("dragged", this.onCircleDrag.bind(this));
    dragCircle.on("dragActive", this.onActiveDrag.bind(this));
    dragCircle.on("dragInactive", this.onInctiveDrag.bind(this));
    interactives.push(dragCircle);

    // Cue button
    const button = new CueButton();
    button.position.set(window.innerWidth / 2, window.innerHeight * 3 / 4 );
    container.addChild(button);
    button.on("pressed", this.onCuePress.bind(this));
    interactives.push(button);

    // Demo fermata
    const fermataCircle = new Fermata();
    fermataCircle.setOrigin(window.innerWidth / 2 + 6 * 64, window.innerHeight * 3 / 4 + 100);
    container.addChild(fermataCircle);
    interactives.push(fermataCircle);

    setInterval(() => {
      track.playbackRate = 1 - fermataCircle.value * 0.5;
    }, 100);

    // Assemble cues
    const cues: Array<[number, InteractiveCue]> = [];

    // Combine cues from all interactives
    interactives.forEach((ii) => {
      cues.push(...ii.cues.map<[number, InteractiveCue]>((cue) => [cue[0], [ii, cue[1], cue[2]]]));
    });

    // Sort all cues ascending
    cues.sort((a, b) => Math.sign(a[0] - b[0]));


    // Click track for syncing up
    this.clickTrack = new ClickTrack<InteractiveCue>({
      timerSource: () => track.currentTime,
      tempo,
      offset,
      cues: cues
    });

    this.clickTrack.on("cue", this.handleClickCue.bind(this));

    app.renderer.backgroundColor = 0x55aacc;

    return container;
  }

  handleClickCue(clicktrack: ClickTrack, cue: CueEvent<InteractiveCue>) {
    if(cue.data && cue.data[0]) {
      const [interactive, state, value] = cue.data;
      interactive.onCue(cue.cue, state, value);
    }
  }

  onCuePress() {
    this.interactives.forEach((i) => {
      if (i && i instanceof InteractiveInstrument) {
        if(i.state === InstrumentState.CUED) {
          i.setState(InstrumentState.HIT);
        }
      }
    });
  }

  onCircleDrag(dragging: Draggable, e: PIXI.interaction.InteractionEvent) {
    if (this.app) {
      const i = this.app.renderer.plugins.interaction.hitTest(e.data.global, this.interactivesContainer);
      if (i && i instanceof InteractiveInstrument) {
        i.emit("mousedragout", this.mousePos);
        i.emit("drop", dragging, e);
      }
    }
  }

  onActiveDrag(dragging: Draggable, e: PIXI.interaction.InteractionEvent) {
    if(!this.draggingObject) {
      this.draggingObject = dragging;
    }
  }

  onInctiveDrag(dragging: Draggable, e: PIXI.interaction.InteractionEvent) {
    if(dragging == this.draggingObject) {
      this.draggingObject = undefined;
    }
  }

  onMove(e: PIXI.interaction.InteractionEvent) {
    this.mousePos = e.data.global;
    this.mouseChecked = false;
  }

  onTick() {
    const currentBeat = this.clickTrack.beat;
    for (let i = 0; i < this.interactives.length; i++) {
      this.interactives[i].onTick(currentBeat);
    }

    // DIY mouse enter/mouse out interaction handling
    // This is here because while draggin draggables, mouseenter and mouseout events aren't triggered
    if(this.draggingObject && this.app && !this.mouseChecked) {
      this.mouseChecked = true;
      const object = this.app.renderer.plugins.interaction.hitTest(this.mousePos, this.interactivesContainer);
      if (object && object instanceof Interactive) {
        if(!this.interactiveHovering) {
          // Mouse enter
          this.interactiveHovering = object;
          this.interactiveHovering.emit("mousedragover", this.mousePos);
        } else if(this.interactiveHovering !== object) {
          // Mouse enter and out (new object)
          object.emit("mousedragover", this.mousePos);
          this.interactiveHovering.emit("mousedragout", this.mousePos);
          this.interactiveHovering = object;
        }
      } else if(this.interactiveHovering) {
        // mouse out
        this.interactiveHovering.emit("mousedragout", this.mousePos);
        this.interactiveHovering = undefined;
      }
    }
  }

  onResize(size: { width: number, height: number }) {
    const multiplier = (size.height - 50) * 3 / 40;
    this.interactivesContainer.position.set(window.innerWidth / 2, window.innerHeight * 3 / 4);
    this.interactives.forEach((s1) => {
      s1.multiplierResize(multiplier);
    });
  }

  async cleanUp() {

  }

}